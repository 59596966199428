import React, { useState } from 'react';
import {doLogin, getCredentials} from "./Aws";

function Login({setCredentials}) {



    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [isLoading, setIsLoading] = useState(true);


    //Intentamos refrescar ñas credenciales
    // TODO Esto hay que moverlo de aquí
    //setIsLoading(true);
    getCredentials().then(x =>  {
        setCredentials(x);
        if(!x) {
            setIsLoading(false);
        }
        //setIsLoading(false);
    })

    const handleSubmit = async e => {
        e.preventDefault();
        //console.log(username);
        //console.log(password);
        try {
            await doLogin(
                username,
                password
            );
            const credentials = await getCredentials();
            setCredentials(credentials);
        } catch(e) {
            if(e.name === 'NotAuthorizedException') {
                alert("Usuario o contraseña erróneos")
            }
            console.log(JSON.stringify(e));
        }
    }

    return (
        <section className="hero is-link is-fullheight">
            <div className={`modal ${isLoading ? 'is-active' : ''}`}>
                <div className="modal-background"/>
                <div className="modal-content">
                    <div className="box">
                        Espera un momento...
                    </div>
                </div>
            </div>
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-5-tablet is-4-desktop is-3-widescreen">
                            <form onSubmit={handleSubmit} className="box">
                                <div className="field">
                                    <label htmlFor="" className="label">Usuario</label>
                                    <div className="control">
                                        <input type="text" className="input"
                                               onChange={e => setUserName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field">
                                    <label htmlFor="" className="label">Contraseña</label>
                                    <div className="control">
                                        <input type="password" className="input"
                                               onChange={e => setPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field">
                                    <button className="button is-success">
                                        Login
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Login;
