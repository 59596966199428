import React, {useState} from 'react';
import './App.css';
import getClientes from "./Model";
import {getCredentials, getCurrentCredentials} from "./Aws";
import Login from "./Login"


function App() {

    function formatDate(string) {
        if(!string) {
            return "SIN ENVIAR"
        }
        const d = new Date(string);
        return d.getMonth() + 1 + "/" + d.getFullYear();
    }

    //doLogin("admin", "admin123");

    /*const printCredentials = async (e) => {
        e.preventDefault();
        let credentials = await getCredentials();
        console.log(credentials);
        if (!credentials) {
            setCredentials(null);
        }
    }*/

    const buscaClientes = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            let credentials = await getCredentials();
            //console.log(credentials);
            if (!credentials) {
                setCredentials(null);
                setResultados({count: -1})
            } else {
                //console.log(cadena);
                const res = await getClientes(cadena, credentials);
                setResultados(res);
            }
        } catch (e) {
            alert(e);
        } finally {
            setIsLoading(false);
        }

        //console.log(credentials);
        //if (!credentials) {
        //setCredentials(null);
        //}
    }

    //const {credentials, setCredentials}  = getCurrentCredentials();
    const [resultados, setResultados] = useState(
        {
            count: -1
        }
    );
    const [cadena, setCadena] = useState("")
    const [credentials, setCredentials] = useState(getCurrentCredentials());
    const [isLoading, setIsLoading] = useState(false);

    //const credentials = getCurrentCredentials();
    //console.log(credentials);
    if (credentials) {
        return (
            <section className="section">
                <div className={`modal ${isLoading ? 'is-active' : ''}`}>
                    <div className="modal-background"/>
                    <div className="modal-content">
                        <div className="box">
                            Espera un momento...
                        </div>
                    </div>
                </div>
                <div className="container">
                    <h1 className="title">Búsqueda de clientes</h1>
                    <form onSubmit={buscaClientes}>
                        <div className="field has-addons">
                            <div className="control"><input className="input" name="cadena"
                                                            placeholder="nombre, cif, email, telf." type="text"
                                                            onChange={e => setCadena(e.target.value)}/>
                            </div>
                            <div className="control"><input className="button is-info" type="submit" value="Buscar"/>
                            </div>
                        </div>
                    </form>
                    <br/>
                    {resultados.count !== -1
                        ? <div>
                            {resultados.demasiados
                                ?
                                <div>
                                    <h2 className="subtitle">Demasiados resultados. Refine su búsqueda</h2>
                                </div>
                                :
                                <div>
                                    <h2 className="subtitle">Número de resultados: {resultados.count}</h2>
                                    {resultados.count > 0
                                        ?
                                        <table className="table is-striped is-narrow" >
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Empresa</th>
                                                <th>Contacto</th>
                                                <th>Delegación</th>
                                                <th>Servicios</th>
                                                <th>¿Baja?</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {resultados.rows.map((row, index) => {
                                                return (
                                                    <tr key={row.id}>
                                                        <td>{row.id}</td>
                                                        <td><b>{row.nombre}</b><br/>{row.cif}</td>
                                                        <td>R. legal: {row.representante_legal}<p>P. contacto: {row.persona_contacto}</p><p>Telf: {row.telefono}</p><p>Email: {row.email}</p><p>Email persona contacto: {row.email_persona_contacto}</p></td>
                                                        <td>{row.delegacion}</td>
                                                        <td className="content"><ul>{row.servicios.map(s => <li key={s.id}><b>{formatDate(s.fecha_espera)} ({s.precio}€)</b><br/>{s.nombre}</li>)}</ul></td>
                                                        <td>{row.baja ? 'Sí' : 'No'}</td>
                                                    </tr>
                                                );
                                            })}

                                            </tbody>
                                        </table>
                                        :
                                        <div/>
                                    }
                                </div>
                            }
                        </div>
                        : <h2 className="subtitle">Introduzca texto y pulse en Buscar</h2>
                    }

                </div>
            </section>
        );
    } else {
        return (
            <Login setCredentials={setCredentials}>Login</Login>
        );
    }

}

export default App;
