import {CognitoIdentityProviderClient, InitiateAuthCommand} from "@aws-sdk/client-cognito-identity-provider";
import {CognitoIdentityClient, GetCredentialsForIdentityCommand, GetIdCommand} from "@aws-sdk/client-cognito-identity";

const region = "eu-west-1";
const clientParams = {region}
const ClientId = "20lkbbhpdp89fmip523fo031dg";
const AuthFlow = "USER_PASSWORD_AUTH";
const AuthFlowRefresh = "REFRESH_TOKEN_AUTH"
const PoolId = 'eu-west-1_MbB1It1BG';
const LoginProvider = `cognito-idp.${region}.amazonaws.com/${PoolId}`;
const IdentityPoolId = "eu-west-1:ef6667ff-00b9-4fba-9890-defddcaeb67a";

// 5 minutos (cuando a demanda)
const EXPIRATION = 5 * 60 * 1000;

//Refrescamos cada 50 minutos
setInterval(() => {
    doRefreshTokens();
}, 3000000);


//Devuelve tokens
const login = async (username, password) => {
    try {
        const client = new CognitoIdentityProviderClient(
            clientParams
        );
        const params = {
            ClientId,
            AuthFlow,
            AuthParameters: {
                USERNAME: username,
                PASSWORD: password
            }
        };
        const command = new InitiateAuthCommand(params);
        const data = await client.send(command);
        return data.AuthenticationResult;
    } catch (e) {
        console.log("Error en login.", e);
        throw e;
    }
};

//Devuelve credenciales AWS
const getAWSCredentials = async (idToken) => {
    const cognitoIdentityClient = new CognitoIdentityClient(
        clientParams
    );

    const paramsGetId = {
        IdentityPoolId,
        Logins: {
            [LoginProvider]: idToken
        }
    }
    const commandGetId = new GetIdCommand(paramsGetId)

    const dataGetId = await cognitoIdentityClient.send(commandGetId);

    //console.log(dataGetId);

    const paramsGetCred = {
        IdentityId: dataGetId.IdentityId,
        Logins: {
            [LoginProvider]: idToken
        }
    }

    const commandGetCredentials = new GetCredentialsForIdentityCommand(paramsGetCred)

    const dataGetCredentials = await cognitoIdentityClient.send(commandGetCredentials);

    //console.log(dataGetCredentials.Credentials);

    let creds = dataGetCredentials.Credentials;

    const credentials = {
        accessKeyId: creds.AccessKeyId,
        expiration: creds.Expiration,
        secretAccessKey: creds.SecretKey,
        sessionToken: creds.SessionToken
    }

    return credentials;
}

const refreshTokens = async (refreshToken) => {
    try {
        const client = new CognitoIdentityProviderClient(
            clientParams
        );
        const params = {
            ClientId,
            AuthFlow: AuthFlowRefresh,
            AuthParameters: {
                REFRESH_TOKEN: refreshToken
            }
        };
        const command = new InitiateAuthCommand(params);
        const data = await client.send(command);
        return data.AuthenticationResult;
    } catch (e) {
        console.log("Error en login.", e);
        throw e;
    }
};

export const doLogin = async (username, password) => {
    console.log("doLogin");
    let tokensUserPool = await login(username, password);
    //console.log("Original:", tokensUserPool);
    localStorage.setItem("tokens_user_pool", JSON.stringify(tokensUserPool));
    let tokensIdentityPool = await getAWSCredentials(tokensUserPool.IdToken);
    localStorage.setItem("tokens_identity_pool", JSON.stringify(tokensIdentityPool));
}

export const doRefreshTokens = async () => {
    console.log("doRefreshTokens");
    const ts = localStorage.getItem("tokens_user_pool");
    if (ts) {
        let tokensUserPool = JSON.parse(ts);
        let refreshToken = tokensUserPool.RefreshToken;
        try {
            let newTokensUserPool = await refreshTokens(refreshToken);
            tokensUserPool.IdToken = newTokensUserPool.IdToken;
            tokensUserPool.AccessToken = newTokensUserPool.AccessToken;
            //console.log("Refresh: ", tokensUserPool);
            localStorage.setItem("tokens_user_pool", JSON.stringify(tokensUserPool));
            let tokensIdentityPool = await getAWSCredentials(tokensUserPool.IdToken);
            localStorage.setItem("tokens_identity_pool", JSON.stringify(tokensIdentityPool));
        } catch (e) {
            console.log(e);
            //Suponemos que ha caducado el refresh token
            localStorage.removeItem("tokens_user_pool");
            localStorage.removeItem("tokens_identity_pool");
        }

    }


}

export const getCredentials = async () => {
    let actuales = getCurrentCredentials();

    if (!actuales) {
        let item = localStorage.getItem("tokens_user_pool");
        if (item) { // Tenemos refresh token
            await doRefreshTokens();
            return getCurrentCredentials();
        }
    }
    return actuales;
}

export const getCurrentCredentials = () => {
    let ts = localStorage.getItem("tokens_identity_pool");
    if (!ts) {
        return null;
    }
    let actuales = JSON.parse(ts);
    const expiration = new Date(actuales.expiration);
    const now = new Date();
    if (expiration - now < EXPIRATION) {
        return null;
    }
    return actuales;
}


