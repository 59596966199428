import { InvokeCommand, LambdaClient } from "@aws-sdk/client-lambda";


const region = "eu-west-1";
const clientParams = { region }

const getClientes = async (cadena, credentials) => {
    const lambdaClient = new LambdaClient(
        {
            ...clientParams,
            credentials: credentials
        });
    const paramsLambda = {
        FunctionName: 'cons-clientes-be-consultaClientesFunction-P8HCUXU0E7KP', /* required */
        Payload: JSON.stringify({ cadena: cadena })
    };


    const invokeCommand = new InvokeCommand(paramsLambda)

    const dataInvoke = await lambdaClient.send(invokeCommand);
    const string = new TextDecoder("utf-8").decode(dataInvoke.Payload);

    let res = JSON.parse(string)
    //console.log(res);
    return res;
}

export default getClientes;
